import React, { Component } from 'react';
import './App.css';
import back from './images/goback.png';
import select from './images/select.png';
import unselect from './images/unselect.png';
import './controls.css'

class shakaPlayer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showHideResolutionBtn: false,
			showLoader:true,
		 };
		this.maxFrameRate = 0;
		this.loadManifestUrl();
	}


	loadManifestUrl() {

		const bodyTag = document.querySelector("body");
        const shakaScript = document.createElement("script");
		const lcevcScript = document.createElement("script");

		shakaScript.setAttribute(
			"type",
			"text/javascript"
		  );
		  shakaScript.setAttribute(
			"src",
			"https://cdnjs.cloudflare.com/ajax/libs/shaka-player/4.12.6/shaka-player.ui.debug.js"
		  );
		  bodyTag.appendChild(shakaScript);

		  lcevcScript.setAttribute(
          "type",
          "text/javascript"
        );        
		lcevcScript.setAttribute(
			"src",
			"https://unpkg.com/lcevc_dec.js@1.2.0/dist/lcevc_dec.min.js"
		  );
		  bodyTag.appendChild(lcevcScript);

		localStorage.setItem('routefromplayer', 'fromPlayer');
		var userName = localStorage.getItem('userName');
		var manifestURL = localStorage.getItem('manifestURL');
		var videoBaseURI = localStorage.getItem('videoBaseURI');
		var isExternal = localStorage.getItem('external');
		var actualUrl = videoBaseURI + manifestURL;
		this.category = localStorage.getItem('categoryName');
		this.isP3Content = localStorage.getItem('isP3Content');
		this.codec = localStorage.getItem('codec');
		this.loopVideo = localStorage.getItem('loopVideo');
		this.rateControl = localStorage.getItem('rateControl');

		this.contentProfiles = JSON.parse(localStorage.getItem('contentProfiles'));

		if(isExternal == '1') {
			var loadManifest = manifestURL;
		} else {
			var loadManifest = actualUrl;
		}

		if(userName) {
			if((manifestURL != null && manifestURL != undefined)) {
				setTimeout(() => {
					const video = document.getElementById('video');
					const ui = video['ui'];
					const controls = ui.getControls();
					const player = controls.getPlayer();

					window.player = player;
  					window.ui = ui;

					// Enable the LCEVC enhancement.
					player.configure('lcevc.enabled', true);
					player.configure('lcevc.drawLogo', true);

					// Enable LCEVC decoding on Safari
					player.configure('streaming.useNativeHlsOnSafari', false);
					player.configure('mediaSource.forceTransmux', true);

					// Listen for error events.
					player.addEventListener('error', this.onError);
					controls.addEventListener('error', this.onError);

					// Try to load a manifest.
					// This is an asynchronous process.
					player.load(loadManifest).then(() => {
						// This runs if the asynchronous load is successful.
						console.info('The video has now been loaded!'); // eslint-disable-line
						setTimeout(() => {

							this.manifestExtension = loadManifest.substring(loadManifest.lastIndexOf('.') + 1);

							this.video.play();
  
							this.tracks = window.player.getVariantTracks();

							if (player.isLive() || this.category == "live") {
								ui.configure({ addSeekBar: false })
								document.getElementsByClassName("shaka-current-time")[0].style.display = "none";
							} else {
								ui.configure({ addSeekBar: true })
							}
								

							if (player.isLive() || this.manifestExtension == "mpd") {
								let audiosIds = [...new Set(this.tracks.map((t) => t.audioId))].filter((t) => t != null);
	
								if (audiosIds.length > 1 && this.tracks.length > audiosIds.length) {
									const audioIdToKeep = audiosIds[0];
									this.tracks = this.tracks.filter((track) => track.audioId === audioIdToKeep);
								}
							} 

							const objProfileSelect = document.getElementById('select');
							  let h = '<option value="-1">Auto</option>';
							  this.tracks.sort(function (a, b) {
								  return  b.bandwidth - a.bandwidth;
							  });
							  // Fetch video playback profiles.
							  if (window.player) {
								
								if(this.manifestExtension == "mpd") {
									for (var i = 0; i < this.tracks.length; i++)
										{
											var t =
												'profile ' + [i + 1] +
												'  @ ' + parseFloat( (this.tracks[i].bandwidth / 1024) - (this.tracks[i].audioBandwidth / 1024)).toFixed(0) + ' kbps';
											h += '<option value=' + i + '>' + t + '</option>';
										}
										//console.log(h);
										objProfileSelect.innerHTML = h;
								
								} else {
										for (var i = 0; i < this.tracks.length; i++)
											{
												var t =
													'profile ' + [i + 1] +
													'  @ ' + parseFloat(this.tracks[i].bandwidth / 1024).toFixed(0) + ' kbps';
												h += '<option value=' + i + '>' + t + '</option>';
											}
											//console.log(h);
											objProfileSelect.innerHTML = h;
								}

							  }
  
  
						  },1000);
					}).catch(this.onError); // onError is executed if the asynchronous load fails.

					this.seekInterval = setInterval(() => {
						this.recalcRates();
					}, 1000);

				},3000);
				
				setTimeout(() => {

					this.setState({
				    	showHideResolutionBtn: true,
			        });

					this.showResolutionBtn = document.getElementById("showResolution");
				    this.resolutionSection = document.getElementById("resolutionContainer");
				    this.hideResolutionBtn = document.getElementById("hideResolution");
					this.showResolutionBtn.style.display = "block";
				    this.hideResolutionBtn.style.display = "none";
				    this.resolutionSection.style.display = "none";

				},500);
				
			} else {
			this.props.history.push('/mtab')
			}
		} else {
			this.props.history.push('/')
			window.location.reload();
		}
	}//end of onInit function.

	onError(error) {
		console.error('Error code', error.code, 'object', error);
		var codec = localStorage.getItem('codec');
		if (error.message.includes('CONTENT_UNSUPPORTED_BY_BROWSER')) {
			window.$("#playeralertModal").modal('show');
			window.$('#playeralertbody').html('This video format is not supported by your browser.');
		} else if(codec == '3' && error.code == '4032') {
			window.$("#playeralertModal").modal('show');
			window.$('#playeralertbody').html('Your player supports LCEVC but unfortunately does not support H265.');
		} else if(error.code == '4032') {
			window.$("#playeralertModal").modal('show');
			window.$('#playeralertbody').html('Stream is not supported by the Shaka player.');
		} else {
			window.$("#playeralertModal").modal('show');
			window.$('#playeralertbody').html('Something went wrong, Please try again.');
		}
	}
	

	initFailed() {
		// Handle the failure to load
		console.error('Unable to load the UI library!');
	}

  	componentDidMount() {
  		window.scrollTo(0, 0);
		this.connectionInterval = setInterval(() => {
			this.handleConnectionChange();
		}, 1000);

		this.video = document.getElementById('video');
        this.video.addEventListener("ended", this.isEnded);
		this.video.addEventListener("playing", this.isPlaying);

		if(this.video) {
			this.video.disablePictureInPicture = true
		}

		document.addEventListener('shaka-ui-load-failed', () => this.initFailed());
  	}

  	componentWillUnmount() {
		this.video.removeEventListener("playing", this.isPlaying);
	    this.video.removeEventListener("ended", this.isEnded);
		clearInterval(this.connectionInterval);
	    window.$("#playeralertModal").modal('hide');
		if(window.player) {
			window.player.unload();
			window.player.destroy();
		}
		clearInterval(this.seekInterval);
		this.maxFrameRate = 0;
  	}

	/*handleConnectionChange*/
	handleConnectionChange = () => {
		this.video = document.getElementById('video');
		const condition = navigator.onLine ? 'online' : 'offline';
		if(condition == 'offline') {
			this.video.pause();
			window.$("#playeralertModal").modal('show');
			window.$('#playeralertbody').html('There appears to be a problem with your internet connection. Please check connections and retry.');
		}
	}

	/*IsPlaying function*/
	isPlaying = (event) => {
		this.setState({
		  showLoader: false,
	  	});
  	}
	/*End of IsPlaying function*/


	/*IsEnded function*/
    isEnded = (event) => {
        if(this.loopVideo == 'true') {
            this.video.currentTime = 0;
            this.video.play();
        } else {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            }
			window.player.unload();
			window.player.destroy();
            window.history.back();
        }
      }
    /*End of IsEnded function*/

	/*Function to get the frame rate value*/
	updateMaxFrameRate = () => {
		const frameRate = window.LCEVCdec.instance ? window.LCEVCdec.instance.frameRate : 0;
		if (frameRate > this.maxFrameRate) {
			this.maxFrameRate = frameRate;
			this.maxFrameRate = this.maxFrameRate ? this.maxFrameRate.toFixed(0) : '0';
			document.getElementById('frameRate').innerHTML = 'Frame Rate : ' + this.maxFrameRate + ' fps';
		}
	};

	/*For Video Resolution*/
	recalcRates() {
		this.video = document.getElementById("video");
	  	this.d1 = document.getElementById("totalresolution");
		if (this.video) {

			this.videoStats = window.player.getStats();

			this.updateMaxFrameRate();

			const tracks = window.player.getVariantTracks();

			if(window.LCEVCdec && window.LCEVCdec.instance && window.LCEVCdec.instance.isLcevcPresentlyDecoded) {
				this.d1.innerHTML = window.LCEVCdec.instance ? "Video Resolution : " + window.LCEVCdec.instance.frameWidth + " X " + window.LCEVCdec.instance.frameHeight : 0;
			} else {
				const activeTrack = tracks.find(t => t.active);
				this.d1.innerHTML = activeTrack ? "Video Resolution : " + activeTrack.width + " X " + activeTrack.height : 0;
			}

			for (var iterator = 0; iterator < tracks.length; iterator++) {
				if (tracks[iterator].active) {

					if(this.manifestExtension == "mpd") {
						var bitrateVal = ((tracks[iterator].bandwidth / 1024) - (tracks[iterator].audioBandwidth / 1024)).toFixed(0);
					} else {
						var bitrateVal = (tracks[iterator].bandwidth / 1024).toFixed(0);
					}

					if (bitrateVal > 999) {
						document.getElementById( 'videoBitrate' ).innerHTML = 'Video Bitrate : ' + (bitrateVal / 1000).toFixed(1) + ' Mbps';
					} else {
						document.getElementById( 'videoBitrate' ).innerHTML = 'Video Bitrate : ' + bitrateVal + ' Kbps';
					}
					
					break;
				}
			}

			if (document.getElementsByTagName('img')[0].hidden) {
				if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('avc1')) {
				var videoCodec = 'H.264';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('av01')) {
				var videoCodec = 'AV1';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('vp09')) {
				var videoCodec = 'VP9';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hvc1')) {
				var videoCodec = 'HEVC';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hev1')) {
					var videoCodec = 'HEVC';
				}
			} else {
				if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('avc1')) {
				var videoCodec = 'LCEVC H.264';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('av01')) {
				var videoCodec = 'LCEVC AV1';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('vp09')) {
				var videoCodec = 'LCEVC VP9';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hvc1')) {
				var videoCodec = 'LCEVC HEVC';
				} else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hev1')) {
					var videoCodec = 'LCEVC HEVC';
				}
			}

			if(videoCodec) {
			document.getElementById( 'videoCodec').innerHTML = 'Video Codec : ' + videoCodec;
			}


			// if (this.rateControl == '1') {
			// 	for (var profile = 0; profile < this.contentProfiles.length; profile++) {
			// 		if(this.contentProfiles[profile].Resolution == (this.videoStats.width + "X" + this.videoStats.height) ) {
			// 			document.getElementById( 'videoBitrate' ).innerHTML = 'pCRF Value : ' + (this.contentProfiles[profile].Bitrate / 1024).toFixed(0);
			// 		}
			// 	}
			// }

		}
  	}


	render() {

		this.iOSCheck = (/iPhone/i.test(navigator.userAgent));
		

		this.changeProfile = (event) => {
			var newLevel = event.target.value; 
			//console.log(newLevel);
			if (document.getElementById('select').value === '-1') {
			  window.player.configure({ abr: { enabled: true } });
			} else {
			  const track = this.tracks[document.getElementById('select').value];
			  window.player.configure({ abr: { enabled: false } });
			  window.player.selectVariantTrack(track, false, 0);
			}
		};

		this.hideResolution = () => (event)  => {
			this.showResolutionBtn.style.display = "block";
			this.hideResolutionBtn.style.display = "none";
			this.resolutionSection.style.display = "none";
		}
		
		this.showResolution = () => (event) => {
			this.showResolutionBtn.style.display = "none";
			this.hideResolutionBtn.style.display = "block";
			this.resolutionSection.style.display = "block";
		}

		this.goback = () => (event) => {
			if (document.fullscreenElement) {
			  document.exitFullscreen();
			}
			window.history.back();
		}
	
   		return (
			
			<div style={{backgroundColor: '#000'}}>

				<div data-shaka-player-container id="videoContainer" style={{maxWidth: '100%'}}>
					
					<video data-shaka-player id="video" playsInline autoPlay={!this.iOSCheck} style={{width: '100%', height: '100vh', backgroundColor: '#000000'}}></video>
									
					{/*LCEVC logo, fullscreen and info buttons*/}
					<div id="top_label" className="ptoplabel">
						<span>&nbsp;&nbsp;<img id="goback" onClick={this.goback()} className="pplayergoback" src={back} />&nbsp;&nbsp;</span>
					
						<div className="ptoplabelcontainer">

							<select className="select select_player pprofilesettings" id="select"  onFocus={this.profileFocus} onChange={this.changeProfile}>
								<option value="-1">Auto</option>
							</select>

							{ 
							(this.state.showHideResolutionBtn)?( 
								<img id="hideResolution" onClick={this.hideResolution()} className="presoultionbtn" src={select} />
							) : ( <span></span> ) 
							}
							
							<img id="showResolution" onClick={this.showResolution()} className="presoultionbtn" src={unselect} />
						</div>
					</div>

					{/*Video Resolution and other infos*/}
					<div id="resolutionContainer" className="presolutionContainer">
						<div className="presolutioncontent">
							<div id="totalresolution"></div>
							<div id="videoBitrate"></div>
							<div id="videoCodec"></div>
							<div id="frameRate"></div>
						</div>
					</div>


					<div> 
						{ 
							(this.state.showLoader)?( 
								<div id="loading"></div>
							) : ( <span></span> ) 
						}
					</div>

					<div className="modal fade" id="playeralertModal" role="dialog">
						<div className="modal-dialog" >
							<div className="modal-content">
								<div className="modal-body" id="playeralertbody">
								</div>
								<div className="playeralert_modal_btn_container">
									<span className="cursor_pointer playeralert_modal_btn" data-dismiss="modal" onClick={this.goback()}>Ok</span>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>

      	);
   	}
}

export default shakaPlayer;