import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import logout from './images/logout.png';
import logo from './images/logo_white.png';
import back from './images/goback.png';
import liveBtn from './images/live.png';
import replacement from './images/replacement.png';
import posterReplacement from './images/poster_replacement.png';
import premium from './images/premium.png';
import play from './images/1.png';
import detail from './images/settings.png';
import * as constant from './Constant';

class AssetDetails extends React.Component {

   constructor(props) {
    super(props);
    var self = this;
    this.state = {
      showSubDialog: 'false',
      showPlayerDialog: 'false',
      searchData: [],
    };
    this.onInit();
  }

  onInit() {
    const fromPlayerRoute = localStorage.getItem('routefromplayer');
    if(this.props.location.pathname == '/contentdetails' && fromPlayerRoute == 'fromPlayer') {
      localStorage.setItem('routefromplayer', '');
    }
    
    localStorage.setItem('routefromContentDetail', 'fromContentDetail');
    var userName = localStorage.getItem('userName');
    var contentId = localStorage.getItem('contentId');

    if(userName) {
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('fromRoute', 'mtab');
      if(contentId == null) {
        this.props.history.push('/mtab');
        window.location.reload();
      }
    } else {
        this.props.history.push('/')
        window.location.reload();
      }
  }

  componentDidMount() {
    this.setState({showSubDialog: 'false'});
    this.setState({showPlayerDialog: 'false'});
    window.scrollTo(0, 0);
  }

 render() {
  /**
  * Get local storage data's
  */
      const userName = localStorage.getItem('userName');
      const ratingEnable = localStorage.getItem('ratingEnable');
      const durationEnable = localStorage.getItem('durationEnable');
      const title = localStorage.getItem('title');
      const contentId = localStorage.getItem('contentId');
      const manifestURL = localStorage.getItem('contentUrl');
      const graphicAssets = JSON.parse(localStorage.getItem('graphicAssets'));
      const contentImage = localStorage.getItem('contentImage');
      const contentImagePpro = localStorage.getItem('contentImagePpro');
      const contentPosterImage = localStorage.getItem('contentPosterImage');
      const contentPosterImagePpro = localStorage.getItem('contentPosterImagePpro');
      const description = localStorage.getItem('description');
      const ContentCategoryName = localStorage.getItem('categoryName');
      const ContentDuration = localStorage.getItem('duration');
      const ContentRating = localStorage.getItem('rating');
      const premiumContent = localStorage.getItem('premium');
      const welcomeMsg = localStorage.getItem('welcomeMsg');
      const imageBaseURI = localStorage.getItem('imageBaseURI');
      const videoBaseURI = localStorage.getItem('videoBaseURI');
      const bgColor = localStorage.getItem('bgColor');
      const textColor = localStorage.getItem('textColor');
      const overlayBackground = localStorage.getItem('overlayBackground');
      const isExternal = localStorage.getItem('external');
      const imagetypeforposter = localStorage.getItem('imagetypeforposter');
      const isStaging = localStorage.getItem('isStaging');
      const imageCacheValue = localStorage.getItem('imageCacheValue');
      const isP3Content = localStorage.getItem('isP3Content');
      const chunksize = localStorage.getItem('chunksize');
      const packaging = localStorage.getItem('packaging');
      const codec = localStorage.getItem('codec');
      const contentProfiles = JSON.parse(localStorage.getItem('contentProfiles'));
      //const contentPproImage = localStorage.getItem('contentPproImage');
      const contentStats = localStorage.getItem('contentStats');
      const rateControl = localStorage.getItem('rateControl');
      const contentStatsText = localStorage.getItem('contentStatsText');

      var jfinalLists = JSON.parse(localStorage.getItem("jfinalLists"));
      var listOfLive = JSON.parse(localStorage.getItem("listOfLive"));
      //console.log(ContentCategoryName);
      //console.log(graphicAssets);
      //console.log(title);
      //console.log(isExternal);

      if(ContentDuration) {
         var a = ContentDuration.split(':');
            if(a[0] != '00') {
              var hr = a[0]+'h:';
            }
              if(a[1] != '00') {
                var min = a[1]+'m:';
              }
              if(a[2] != '00') {
                var sec = Math.round(a[2])+'s';
              }
              if(hr) {
                var time = hr + min + sec;
              } else if(min){
                time = min + sec;
              } else {
                time = sec;
              }
        } else {
            time = "No Duration"
          }
      
      /**
      * Internal styles
      */
      const colors = {
        backgroundColor: bgColor,
        color: textColor,
      }
      const footercontainer = {
        backgroundColor: bgColor,
        color: textColor,
        positio: 'fixed',
        top: '0'
      }
      const assetOverlaybg = {
        backgroundColor : overlayBackground,
      }
      const assetLogoPart = {
        backgroundColor: bgColor,
        color: textColor,
        padding: '0px',
      }
      const playIcon = {
        cursor:'pointer',
      }
      const vodAssetContainer = {
        textAlign: 'center',
        width: '100%',
        backgroundColor: bgColor,
        color: textColor
      }

      /**
      * Render Functions
      */
      this.logout = () => (event) => {
        event.preventDefault();
        localStorage.clear();
        this.props.history.push('/')
      }

      this.goback = () => (event) => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        window.history.back();
      }

      this.onError = (event)  => {
        event.target.style.objectFit = 'contain';
        event.target.style.objectPosition = 'right';
        event.target.src = posterReplacement;
        fetch(imageBaseURI +'images/' +contentId+'.png').then(response => {
          if(response.ok) {
            event.target.style.objectFit = 'fill';
            event.target.src = imageBaseURI +'images/' +contentId+'.png';
          } else {
            //console.log(event);
            event.target.src = posterReplacement;
          }
        });
      }

      this.goToPlayer = (manifestURL,title,category,premiumContent) => (event) => {
        var selectedPlayer = window.$("input[type='radio'][name='playeroption']:checked").val();
        
        var isIOS = (/iPhone/i.test(navigator.userAgent));

        
          localStorage.setItem('manifestURL', manifestURL);
          localStorage.setItem('title', title);
          localStorage.setItem('category', category);
          localStorage.setItem('isP3Content', isP3Content);
          localStorage.setItem('categoryName', ContentCategoryName);

          if(document.getElementById("loop_video")) {
            var loopvideo_val = document.getElementById("loop_video").checked;
           
            localStorage.setItem('loopVideo', loopvideo_val);
          } else {
            localStorage.setItem('loopVideo', 'false');
          }

          var premiumContent = '0';
           
          event.preventDefault();
          

          if(premiumContent == '1') {
            this.setState({showSubDialog: 'true'});
            fetch('https://platform.v-nova.com/vnova_api/staging/entitlement.php', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "username":  userName,
                "master_id": constant.Master_id
              })
            }).then(response =>  response.json())
              .then(resData => {
                //console.log(resData);
                if(resData.status_code == 100) {
                  const condition = navigator.onLine ? 'online' : 'offline';
                  if(condition == 'offline') {
                    alert('There appears to be a problem with your internet connection. Please check connections and retry.');
                  } else {
                    this.setState({showSubDialog: 'false'});
                    this.setState({showPlayerDialog: 'true'});
                    //Player Adapter
                    localStorage.setItem('fromContent', '1');
                    //Redirect to shaka player
                    this.props.history.push('/player')
                    /*if( (codec == '5' || codec == '6' || codec == '7' || codec == '8') ) {
                      this.props.history.push('/splayer')
                    } else {
                      this.props.history.push('/player')
                    }*/
                    /*if(selectedPlayer == '1') {
                      this.props.history.push('/player')
                    } else {
                      this.props.history.push('/splayer')
                    }*/
                    /*if(window.Hls.isSupported()) {
                      this.props.history.push('/player')
                    }*/
                  }
                } else {
                  alert("Subscribe to watch premium content");
                  this.props.history.push('/subscribe')
                }
              }).catch(error => console.log(error));

            } else {
                const condition = navigator.onLine ? 'online' : 'offline';
                  if(condition == 'offline') {
                    alert('There appears to be a problem with your internet connection. Please check connections and retry.');
                  } else {
                    this.setState({showSubDialog: 'false'});
                    this.setState({showPlayerDialog: 'true'});
                    //Player Adapter
                    localStorage.setItem('fromContent', '1');
                    //Redirect to shaka player
                    this.props.history.push('/player')
                    
                  }
            } 
      };



   return (

    <div style={colors} className="assetparent_height">

        {/*logo section*/}
        <div className="col-md-12 col-sm-12 col-xs-12" style={assetLogoPart}>
        <div className="assetdetail_logo_alignment">
        <span className="detailgoback_span"><img id="goback" onClick={this.goback()} className="detailgoback" src={back} /></span>
        <img src={logo} alt="LOGO" className="assetdetail_logo"/>
        {
          (isStaging == 'true') ? (
              <span className="staging_platform staging_platform_assetdetails">STAGING</span>
          ) : (
            <span></span>
          )
        }
        </div>
        
        </div>
      {/*End of logo section*/}

      {/*VOD Contents*/}
      <div className="col-md-12 col-sm-12 col-xs-12 padding_left_right_zero" style={colors}>
        { 
          (ContentCategoryName == 'asset')?( 
            <div  className="col-md-12 col-sm-12 col-xs-12 zero_padding vodAssetContainer" style={vodAssetContainer}>
              <div className="col-md-1 col-sm-1 col-xs-0 zero_padding"></div>
              <div className="col-md-4 col-sm-4 col-xs-12 zero_padding">
              
              {
                (imagetypeforposter == 'p2')?( 

                  <div>
                    <div className="hidden-xs">
                      {
                        (graphicAssets !== undefined && graphicAssets !== null)?
                        (
                          <div onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails cursor_pointer">
                            <perseus-img src={imageBaseURI + graphicAssets.Ppro.Web.Detail} onError={(e) => {e.target.src = posterReplacement }}> </perseus-img>
                          </div>
                        ) : 
                        (
                          <div onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails cursor_pointer">
                            <perseus-img src={imageBaseURI + contentImagePpro} onError={(e) => {e.target.src = posterReplacement }}> </perseus-img>
                          </div>
                         )
                      }
                    </div>
                    
                    <div className="visible-xs">
                      {
                        (graphicAssets !== undefined && graphicAssets !== null)?
                        (
                          <div onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails cursor_pointer">
                            <perseus-img src={imageBaseURI + graphicAssets.Ppro.Web.Poster} onError={(e) => {e.target.src = posterReplacement }}> </perseus-img>
                          </div>
                        ) : 
                        (
                          <div onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails cursor_pointer">
                            <perseus-img src={imageBaseURI + contentPosterImagePpro} onError={(e) => {e.target.src = posterReplacement }}> </perseus-img>
                          </div>
                         )
                      }
                    </div>
                  </div>

                  
                ) : 
                ( 
                  <div>
                    <div className="hidden-xs">
                      {
                        (graphicAssets !== undefined && graphicAssets !== null)?
                        (
                          <img onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails caro_placeholder" src={imageBaseURI + graphicAssets.Png.Web.Detail + '?' + imageCacheValue } onError={(e) => {e.persist();this.onError(e)}} />
                        ) : 
                        (
                          <img onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails caro_placeholder" src={imageBaseURI + contentImage + '?' + imageCacheValue } onError={(e) => {e.persist();this.onError(e)}} />
                         )
                      }
                    </div>

                    <div className="visible-xs">
                      {
                        (graphicAssets !== undefined && graphicAssets !== null)?
                        (
                          <img onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails caro_placeholder" src={imageBaseURI + graphicAssets.Png.Web.Poster + '?' + imageCacheValue } onError={(e) => {e.persist();this.onError(e)}} />
                        ) : 
                        (
                          <img onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails caro_placeholder" src={imageBaseURI + contentPosterImage + '?' + imageCacheValue } onError={(e) => {e.persist();this.onError(e)}}  />
                         )
                      }
                    </div>
                  </div>

                ) 
              }

              <div> 
                { 
                  (premiumContent == '1')?( 
                  <img className="premium_indetails" src={premium} alt="Premium" />
                  ) : ( <span></span> ) 
                }
              </div>
              <img onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="cornerimage" src={play}  style={playIcon}/>
              
              </div>

              <div className="col-md-6 col-sm-6 col-xs-12 zero_padding assetinfoContainer wrapword" style={colors}>
                <div className="contentinfo_container">
                <div className="contentinfo_subcategory">
                  { 
                    (title)?( 
                      <span className="contentinfo_subcategory_title">{title}</span>
                    ) : ( <span></span> ) 
                  }
                </div>
                <div className="contentinfo_subcategory">
                  { 
                    (description && description != 'undefined' && description != 'null')?( 
                      <span className="contentinfo_subcategory_desc space_break"><span className="myaccount_details_name">Description : </span> {description}</span>
                    ) : ( <span className="contentinfo_subcategory_desc"><span className="myaccount_details_name">Description : </span> Description not available</span> ) 
                  }
                </div>
                <div>
                  { 
                    (ratingEnable == '1' && ContentRating && ContentRating != 'undefined' && ContentRating != 'null')?( 
                      <div className="contentinfo_subcategory "><span className="myaccount_details_name">Rating : </span> {ContentRating}</div>
                    ) : ( <span></span> ) 
                  }
                </div>
                <div>
                  { 
                    (durationEnable == '1' && ContentDuration && ContentDuration != 'undefined' && ContentDuration != 'null')?( 
                      <div className="contentinfo_subcategory "><span className="myaccount_details_name">Duration : </span> {time}</div>
                    ) : ( <span></span> ) 
                  }
                </div>

                <div>
                  <div className="contentinfo_subcategory "><span className="loop_checkbox_container"><input className="checkbox_input" type="checkbox" name="loop_video" id="loop_video" /></span><span className="myaccount_details_name"><b>Loop</b></span></div>
                </div>

                <div className="contentinfo_subcategory">
                  {
                    (contentStats == '1' && contentProfiles != null) ? (
                      <span className="cursor_pointer profileModal" data-toggle="modal" data-target="#profileModal">
                        <img src={detail} className="profile_details_img" alt="Content Details"/>&nbsp;&nbsp;
                        <span><b>{contentStatsText}</b></span>
                      </span>
                      ) : ( <span></span> )
                  }
                </div>

                {/*<div>
                  <span>Choose a desire player:</span><br/>
                  <input type="radio" id="hls_palyer" name="playeroption" value="1" defaultChecked  />
                  <label>hls.js</label><span>&emsp;</span>
                  <input type="radio" id="shaka_player" name="playeroption" value="2" />
                  <label>shaka</label>
                </div>*/}

                </div>
              </div>

              <div className="col-md-1 col-sm-1 col-xs-0 zero_padding"></div>

            </div>

          ) : ( 

            <div  className="col-md-12 col-sm-12 col-xs-12 zero_padding vodAssetContainer" style={vodAssetContainer}>
              <div className="col-md-1 col-sm-1 col-xs-0 zero_padding"></div>
              <div className="col-md-4 col-sm-4 col-xs-12 zero_padding">
              
              {
                (imagetypeforposter == 'p2')?( 

                  <div>
                    <div className="hidden-xs">
                      {
                        (graphicAssets !== undefined && graphicAssets !== null)?
                        (
                          <div onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails cursor_pointer">
                            <perseus-img src={imageBaseURI + graphicAssets.Ppro.Web.Detail} onError={(e) => {e.target.src = posterReplacement }}> </perseus-img>
                          </div>
                        ) : 
                        (
                          <div onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails cursor_pointer">
                            <perseus-img src={imageBaseURI + contentImagePpro} onError={(e) => {e.target.src = posterReplacement }}> </perseus-img>
                          </div>
                         )
                      }
                    </div>
                    
                    <div className="visible-xs">
                      {
                        (graphicAssets !== undefined && graphicAssets !== null)?
                        (
                          <div onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails cursor_pointer">
                            <perseus-img src={imageBaseURI + graphicAssets.Ppro.Web.Detail} onError={(e) => {e.target.src = posterReplacement }}> </perseus-img>
                          </div>
                        ) : 
                        (
                          <div onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails cursor_pointer">
                            <perseus-img src={imageBaseURI + contentPosterImagePpro} onError={(e) => {e.target.src = posterReplacement }}> </perseus-img>
                          </div>
                         )
                      }
                    </div>
                  </div>

                  
                ) : 
                ( 
                  <div>
                      <div className="hidden-xs">
                      {
                        (graphicAssets !== undefined && graphicAssets !== null)?
                        (
                          <img onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails caro_placeholder" src={imageBaseURI + graphicAssets.Png.Web.Detail + '?' + imageCacheValue } onError={(e) => {e.persist();this.onError(e)}} />
                        ) : 
                        (
                          <img onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails caro_placeholder" src={imageBaseURI + contentImage + '?' + imageCacheValue } onError={(e) => {e.persist();this.onError(e)}} />
                         )
                      }
                    </div>

                    <div className="visible-xs">
                      {
                        (graphicAssets !== undefined && graphicAssets !== null)?
                        (
                          <img onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails caro_placeholder" src={imageBaseURI + graphicAssets.Png.Web.Detail + '?' + imageCacheValue } onError={(e) => {e.persist();this.onError(e)}} />
                        ) : 
                        (
                          <img onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} className="assetImgDetails caro_placeholder" src={imageBaseURI + contentPosterImage + '?' + imageCacheValue } onError={(e) => {e.persist();this.onError(e)}} />
                         )
                      }
                    </div>
                  </div>

                ) 
              }

              <div> 
                { 
                  (ContentCategoryName == 'live')?( 
                  <img className="premium_indetails top_5" src={liveBtn} alt="Live" />
                  ) : ( <span></span> ) 
                }
              </div>

              <img className="cornerimage" onClick={this.goToPlayer(manifestURL,title,ContentCategoryName,premiumContent)} src={play}  style={playIcon}/>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-12 zero_padding liveinfoContainer wrapword" style={colors}>
                <div className="liveinfoContainer_category">
                  { 
                    (title)?( 
                      <span className="contentinfo_subcategory_livetitle">{title}</span>
                    ) : ( <span></span> ) 
                  }
                  <div className="contentinfo_subcategory">
                  { 
                    (description && description != 'undefined' && description != 'null')?( 
                      <span className="contentinfo_subcategory_desc space_break"><span className="myaccount_details_name">Description : </span> {description}</span>
                    ) : ( <span className="contentinfo_subcategory_desc"><span className="myaccount_details_name">Description : </span> Description not available</span> ) 
                  }
                </div>
                </div>
              </div>
              <div className="col-md-1 col-sm-1 col-xs-0 zero_padding"></div>

            </div>

           ) 
        }

        <div>
          { 
            (this.state.showSubDialog == 'true')?( 
              <div>
                <dialog id="subscriptionDialog" className="dialog_popup" open>Checking for subscription...</dialog>
              </div>
            ) : ( <span></span> ) 
          }
        </div>

        <div>
          { 
            (this.state.showPlayerDialog == 'true')?( 
              <div>
                <dialog id="playerAdapterDialog" className="dialog_popup" open>Checking for player adapter...</dialog>
              </div>
            ) : ( <span></span> ) 
          }
        </div>
        
      </div>
      {/*End of VOD contents*/}

      {/*Modal Window*/}
        <div className="modal fade" id="profileModal" role="dialog">
          <div className="modal-dialog profile_modal_dialog" >
            
            { 
              (ContentCategoryName == 'live')?( 
                <span></span>
              ) : ( 

                <div className="modal-content profile_modal_content" style={colors}>
              
                  <div className="modal-body profile_modal_body">
                      <div className="profile_modal_body_title"><b>Content Details</b></div>
                      <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">Title : </span> <span>&nbsp;{title}</span></div>
                      <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">Content ID : </span> <span>&nbsp;{contentId}</span></div>
                      { 
                        (isExternal != '1')?( 
                          <span>
                            <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">Duration : </span> <span>&nbsp;{time}</span></div>
                            <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">Packaging : </span> <span>&nbsp;{packaging}</span></div>
                            <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">Chunksize : </span> <span>&nbsp;{chunksize}</span></div>
                          </span>
                        ) : ( <span></span> ) 
                      }
                      {
                        (contentProfiles && isExternal != '1') ? 
                        (
                          <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">No of profiles : </span> <span>&nbsp;{contentProfiles.length}</span></div>
                        ) :
                        (<span></span>)
                      }
                      
                      {
                        (contentProfiles && isExternal != '1') ? 
                        (
                          <div className="profile_modal_body_infos">
                            {contentProfiles.map((profile,i) => 
                              <div key = {i} style={{padding: '8px 0px'}}>
                                <div className="profile_modal_body_infos">Profile {i +1} :</div>
                                <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">Resolution : </span> <span>&nbsp;{profile.Resolution}</span></div>
                                {
                                  (rateControl == '1') ? 
                                  (
                                    <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">pCRF Value : </span> <span>&nbsp;{((profile.Bitrate/1024).toLocaleString(undefined, {maximumFractionDigits:0}))}</span></div>
                                  ) :
                                  (
                                    <span>
                                    {
                                      ((profile.Bitrate/1024) > 999) ? 
                                      (
                                        <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">Bitrate : </span> <span>&nbsp;{( ((profile.Bitrate/1024)/1000).toLocaleString(undefined, {maximumFractionDigits:1}))} Mbps</span></div>
                                      ) :
                                      (
                                        <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">Bitrate : </span> <span>&nbsp;{((profile.Bitrate/1024).toLocaleString(undefined, {maximumFractionDigits:0}))} Kbps</span></div>
                                      )
                                    }
                                    </span>

                                  )
                                }
                                
                                <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">Max Gop : </span> <span>&nbsp;{profile.Maxgop}</span></div>
                                <div className="profile_modal_body_infos"> <span className="profile_modal_body_key">Audio Bitrate : </span> <span>&nbsp;{profile.Audiobitrate}</span></div>
                              </div>
                            )}
                          </div>
                        ) :
                        (<span></span>)
                      }

                      

                  </div>

                  <div className="profile_modal_body_btn_container">
                    <span className="cursor_pointer profile_modal_body_btn" data-dismiss="modal">Ok</span>
                  </div>
             
                </div>

               ) 
            }
            
          </div>
        </div>
      {/*End of Modal Window*/}
      
      {/*<footer className='col-xs-12' style={footercontainer}>Version : staging_mvmo_1.3.0.0</footer>*/}
    </div>


      );
   }
}

export default AssetDetails;